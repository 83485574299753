<template>
	<b-row class="mt-2 mb-5">
		<b-col md="12">
			<validation-observer ref="createEntryValidation">
				<b-form @submit.prevent>
					<b-row>
						<b-col md="6">
							<b-form-group label="Modul" label-for="mc-first-name">
								<!-- <b-form-input v-model="moduleName" id="mc-first-name" placeholder="Modul" /> -->
								<validation-provider #default="{ errors }" name="Modul" rules="required">
									<b-form-select v-model="moduleName" :rules="required" :options="moduleNames" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group label="Bereich" label-for="mc-last-name">
								<validation-provider #default="{ errors }" name="Bereich" rules="required">
									<b-form-input v-model="partName" id="mc-last-name" placeholder="Bereich" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col md="6">
							<b-form-group label="Schlüssel" label-for="mc-city">
								<validation-provider #default="{ errors }" name="Schlüssel" rules="required">
									<b-form-input v-model="key" :rules="required" id="mc-city" placeholder="Schlüssel" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group label="Wert" label-for="mc-country">
								<validation-provider #default="{ errors }" name="Wert" rules="required">
									<b-form-input v-model="value" :rules="required" id="mc-country" placeholder="Wert" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col md="12">
							<b-form-group label="Variablen (min. 2 Zeichen & ohne Leerzeichen)" label-for="mc-variables">
								<b-form-tags
									v-model="variables"
									placeholder="Variable hinzufügen.."
									input-id="mc-variables"
									class="mb-2"
									:tag-validator="tagValidator"
									addButtonText="Hinzufügen"
									invalid-tag-text="Variable erfüllt die Regeln nicht"
								>
								</b-form-tags>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<!-- submit and reset -->
						<b-col class="pt-2 text-right">
							<b-button
								v-ripple.400="'rgba(186, 191, 199, 0.15)'"
								type="reset"
								variant="outline-secondary"
								@click="close"
								class="mr-1"
							>
								Abbrechen
							</b-button>
							<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" @click="createEntry">
								Erstellen
							</b-button>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>
		</b-col>
	</b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import { BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormSelect, BFormTags, BFormInvalidFeedback } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
	name: 'CreateEntry',
	props: {
		closeFn: { type: Function },
	},
	components: {
		BRow,
		BCol,
		BFormGroup,
		BFormInput,
		BForm,
		BButton,
		BFormSelect,
		BFormTags,
		BFormInvalidFeedback,
		ValidationProvider,
		ValidationObserver,
	},
	directives: {
		Ripple,
	},
	data() {
		return {
			valid: true,
			moduleName: null,
			partName: null,
			key: null,
			value: null,
			variables: [],
			loading: false,
			required,
			// required: [
			// 	(v) => {
			// 		return !!v || 'Feld wird benötigt'
			// 	},
			// ],
			moduleNames: [
				{ text: 'Konfigurator', value: 'configurator' },
				{ text: 'Rechnung', value: 'invoice' },
			],
		}
	},
	methods: {
		close() {
			this.$refs.createEntryValidation.reset()
			this.closeFn()
		},
		createEntry() {
			console.log(this.moduleName)
			console.log(this.partName)
			console.log(this.key)
			console.log(this.value)
			console.log(this.variables)

			this.$refs.createEntryValidation.validate().then((success) => {
				console.log(success)
				if (success && !this.loading) {
					this.loading = true
					this.$store
						.dispatch('Dictionary/createEntry', {
							module: this.moduleName,
							part: this.partName.replace(/\s/g, '_'),
							key: this.key.replace(/\s/g, '_'),
							value: this.value,
							variables: this.variables,
							lang: 'de',
						})
						.then(
							() => {
								this.$refs.createEntryValidation.reset()
								this.loading = false
								this.closeFn()
								// this.$refs.form.resetValidation()
							},
							(err) => {
								console.log(err)
								this.loading = false
							}
						)
				}
			})
		},
		tagValidator(tag) {
			// Individual tag validator function
			return tag.match(/\s/gi) === null && tag.length > 2
		},
	},
}
</script>