<template>
	<b-container>
		<b-row>
			<b-col md="4" sm="6" class="my-1">
				<b-form-group class="mb-0">
					<label class="d-inline-block text-sm-left mr-50">Einträge pro Seite</label>
					<b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
				</b-form-group>
			</b-col>
			<b-col md="6" class="my-1">
				<b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
					<b-input-group size="sm">
						<b-form-input id="filterInput" v-model="filter" type="search" placeholder="Suche.." />
						<b-input-group-append>
							<b-button :disabled="!filter" @click="filter = ''"> Löschen </b-button>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col md="2" class="my-1 text-center">
				<b-button @click="addEntryDialog = true" variant="gradient-primary" size="sm" class="ml-2"> Neuer Eintrag </b-button>
			</b-col>

			<b-col cols="12">
				<b-table
					striped
					hover
					responsive
					:per-page="perPage"
					:current-page="currentPage"
					:items="dictionary"
					:fields="fields"
					:filter="filter"
					:filter-included-fields="filterOn"
					@filtered="onFiltered"
					:busy="loading"
				>
					<template #table-busy>
						<div class="text-center my-2 d-flex flex-column align-items-center">
							<b-spinner class="align-middle"></b-spinner>
							<strong class="mt-2">Loading...</strong>
						</div>
					</template>

					<template #cell(frontendKey)="data">
						<div>
							<small>{{ data.item.module }}_{{ data.item.part }}_{{ data.item.key }}</small>
							<!-- <br /> -->
							<b-button
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								variant="outline-primary"
								class="btn-icon ml-1"
								size="sm"
								@click="copyTextToClipboard(`${data.item.module}_${data.item.part}_${data.item.key}`)"
							>
								<feather-icon icon="CopyIcon" />
							</b-button>
						</div>
					</template>

					<template #cell(value)="data">
						<div v-if="editEntryId !== data.item._id">
							<div>
								<i>{{ data.item.value }}</i>
							</div>
							<div class="mt-2" v-if="data.item.variables && data.item.variables.length > 0">
								<small>
									<b>
										Verfügbare Variablen:
										<span v-for="(variable, idx) of data.item.variables" :key="idx" :style="{ padding: '0 6px' }">{{
											variable
										}}</span>
									</b>
								</small>
							</div>
						</div>
						<div v-if="editEntryId === data.item._id">
							<!-- <v-textarea v-model="data.item.value" outlined style="font-size: 10.5px" hide-details class="ma-2"></v-textarea> -->
							<div>
								<b-form-textarea id="textarea-default" placeholder="Textarea" rows="3" v-model="editValue" />
							</div>
							<b-form-tags
								v-model="data.item.variables"
								placeholder="Variable hinzufügen.."
								input-id="mc-variables"
								class="mt-2"
								:tag-validator="tagValidator"
								addButtonText="Hinzufügen"
								invalid-tag-text="Variable erfüllt die Regeln nicht"
							>
							</b-form-tags>
						</div>
					</template>

					<template #cell(actions)="data">
						<div class="text-center d-flex">
							<b-button
								@click.stop="startEdit(data)"
								variant="gradient-primary"
								class="btn-icon rounded-circle ml-2"
								v-if="editEntryId !== data.item._id"
							>
								<feather-icon icon="Edit2Icon" />
							</b-button>
							<b-button
								@click.stop="saveEdit(data.item)"
								variant="gradient-success"
								class="btn-icon rounded-circle ml-2"
								v-if="editEntryId === data.item._id"
							>
								<feather-icon icon="SaveIcon" />
							</b-button>
							<b-button
								variant="gradient-danger"
								class="btn-icon rounded-circle ml-2"
								@click.stop="openDeleteDialog(data.item)"
								v-if="editEntryId !== data.item._id"
							>
								<feather-icon icon="Trash2Icon" />
							</b-button>
						</div>
					</template>
				</b-table>
			</b-col>

			<b-col cols="12">
				<b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm" class="my-0" />
			</b-col>
		</b-row>
		<b-modal
			v-model="deleteDialog"
			ok-variant="danger"
			ok-title="Löschen"
			cancel-title="Abbrechen"
			cancel-variant="outline-secondary"
			@ok="deletedEntry"
			@cancel="closeDeleteDialog"
			modal-class="modal-danger"
			centered
			title="Eintrag löschen?"
		>
			<b-card-text>
				Eintrag wirklich löschen? <br />
				Diese Aktion kann nicht rückgängig gemacht werden.
			</b-card-text>
		</b-modal>
		<b-modal v-model="addEntryDialog" size="lg" hide-footer modal-class="modal-primary" centered title="Neuer Eintrag">
			<CreateEntry :closeFn="() => (addEntryDialog = false)" />
		</b-modal>
	</b-container>
</template>

<script>
import {
	BTable,
	//   BAvatar,
	//   BBadge,
	BRow,
	BCol,
	BFormGroup,
	BFormSelect,
	BPagination,
	BInputGroup,
	BFormInput,
	BInputGroupAppend,
	BButton,
	BContainer,
	BFormTextarea,
	BCardText,
	BFormTags,
	BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { mapFields } from 'vuex-map-fields'

import CreateEntry from './CreateEntry'

export default {
	name: 'Dictionary',
	components: {
		BTable,
		// BAvatar,
		// BBadge,
		BContainer,
		BRow,
		BCol,
		BFormGroup,
		BFormSelect,
		BPagination,
		BInputGroup,
		BFormInput,
		BInputGroupAppend,
		BButton,
		BFormTextarea,
		BCardText,
		BFormTags,
		CreateEntry,
		BSpinner,
	},
	directives: {
		Ripple,
	},
	data() {
		return {
			editValue: '',
			loading: false,
			editEntryId: null,
			deleteDialog: false,
			deleteItem: null,
			deleting: false,
			addEntryDialog: false,
			perPage: 10,
			pageOptions: [10, 20, 50],
			totalRows: 1,
			currentPage: 1,
			filter: null,
			filterOn: [],
			fields: [
				// { label: 'Modul', key: 'module', sortable: true },
				// { label: 'Bereich', key: 'part', sortable: true },
				// { label: 'Schlüssel', key: 'key', sortable: true },
				{ label: 'Frontend-Schlüssel', key: 'frontendKey', sortable: true, thStyle: { width: '30%' } },
				{ label: 'Text', key: 'value', sortable: true, thStyle: { width: '55%' } },
				{ label: '', key: 'actions', thStyle: { width: '15%' } },
			],
		}
	},
	watch: {
		dictionary(newValue) {
			this.totalRows = newValue.length
		},
	},
	computed: {
		...mapFields('Dictionary', {
			dictionary: 'dictionary',
		}),
		sortOptions() {
			// Create an options list from our fields
			return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
		},
	},
	created() {
		this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', 'boxed')
		// load data
		this.loading = true
		this.$store.dispatch('Dictionary/fetchAll').finally(() => (this.loading = false))
	},
	mounted() {
		// Set the initial number of items
		this.totalRows = this.dictionary.length
	},
	methods: {
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		startEdit(data) {
			this.editValue = data.item.value
			this.editEntryId = data.item._id
		},
		saveEdit(item) {
			const value = this.editValue

			this.$store.dispatch('Dictionary/updateEntry', {
				_id: item._id,
				// value: item.value,
				value,
				variables: item.variables,
			})

			this.editEntryId = null
			item.value = this.editValue
		},
		// edit items methods
		openDeleteDialog(item) {
			this.deleteItem = item
			this.deleteDialog = true
		},
		closeDeleteDialog() {
			this.deleteItem = null
			this.deleteDialog = false
		},
		deletedEntry() {
			this.deleting = true
			this.$store.dispatch('Dictionary/deleteEntry', this.deleteItem._id).then(() => {
				this.deleting = false
				this.deleteItem = null
				this.deleteDialog = false
			})
		},
		fallbackCopyTextToClipboard(text) {
			var textArea = document.createElement('textarea')
			textArea.value = text

			// Avoid scrolling to bottom
			textArea.style.top = '0'
			textArea.style.left = '0'
			textArea.style.position = 'fixed'

			document.body.appendChild(textArea)
			textArea.focus()
			textArea.select()

			try {
				var successful = document.execCommand('copy')
				var msg = successful ? 'successful' : 'unsuccessful'
				console.log('Fallback: Copying text command was ' + msg)
			} catch (err) {
				console.error('Fallback: Oops, unable to copy', err)
			}

			document.body.removeChild(textArea)
		},
		copyTextToClipboard(text) {
			if (!navigator.clipboard) {
				this.fallbackCopyTextToClipboard(text)
				return
			}
			navigator.clipboard.writeText(text).then(
				function () {
					console.log('Async: Copying to clipboard was successful!')
				},
				function (err) {
					console.error('Async: Could not copy text: ', err)
				}
			)
		},
		tagValidator(tag) {
			// Individual tag validator function
			return tag.match(/\s/gi) === null && tag.length > 2
		},
	},
}
</script>